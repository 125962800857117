import React, { Suspense } from 'react'
// import AuthProvider from 'providers/auth'
import 'utils/i18n'
import Loadable from 'react-loadable'
import DocumentLoading from 'components/Loading/Document'

import { ToastContainer } from 'react-toastify';

const DocumentLoadingPage = props => <DocumentLoading text='Please wait......' />

const Routes = Loadable({
  loader: () => import('routes'),
  loading: DocumentLoadingPage,
})
const AuthProvider = Loadable({
  loader: () => import('providers/auth'),
  loading: DocumentLoadingPage,
})

function App() {
  return (
    <Suspense fallback={<DocumentLoadingPage />}>
      <AuthProvider>
        <Routes />
        <ToastContainer newestOnTop />
      </AuthProvider>
    </Suspense >
  )
}

export default App