import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
// import en from 'languages/en'
// import th from 'languages/th'
// import ch from 'languages/ch'
import Backend from 'i18next-xhr-backend'

// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources: {
//       en,
//       th,
//       ch
//     },
//     fallbackLng: 'en',
//     debug: true,
//     // have a common namespace used around the full app
//     ns: ['translations'],
//     defaultNS: 'translations',
//     keySeparator: false, // we use content as keys
//     localeSubpaths: {
//       th: 'th',
//       en: 'en',
//       ch: 'ch'
//     },
//     interpolation: {
//       escapeValue: false
//     }
//   })
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    defaultLanguage: 'en',
    // localeSubpaths: {
    //   th: 'th',
    //   en: 'en',
    //   ch: 'ch'
    // },
    backend: {
      allowMultiLoading: true
    },
    otherLanguages: [
      'th', 'ch'
    ],
    keySeparator: true,
    fallbackLng: 'en',
    debug: false,
    react: {
      omitBoundRerender: false,
      wait: false,
      useSuspense: true
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
